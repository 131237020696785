body {
	margin: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
/*	background-image: url('./assets/img/clipart/bgGreen.png');
	background-repeat: repeat;
	
	background-color: #434343;*/
}



code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}



/* Custom CSS below */


.speech-bubble {
	position: relative;
	border-color: black;
	border-radius: .4em;
	border-style: solid;
	border-width: 1px;
	padding: 0.2rem;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: black;
	background-color: white;
	border-left: -10px;
	border-bottom: 0;
	margin-top: -10px;
	margin-left: -20px;
}


.spinner-container {
	width: 20rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 1;
}

.spinner {
	width: 4rem;
	height: 4rem;
	border: 0px solid;
	border-color: #3d5af1 transparent #3d5af1 transparent;
	border-radius: 50%;
	animation: spin-anim 1.5s linear infinite;
}

.spinner-text {
	color: grey;
	animation: blink-anim 1.5s alternate infinite;
}

.slide-in {
	position: relative;
	left: 0;
	height: auto;
	animation: slide-in-anim 0.5s linear forwards;
}

@keyframes blink-anim {
	0% {
		color: white
	}

	100% {
		color: grey;
	}
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);

		width: 3rem;
		height: 3rem;
	}

	50% {
		width: 4rem;
		height: 4rem;
	}

	100% {
		transform: rotate(360deg);
		width: 3rem;
		height: 3rem;
	}
}

@keyframes slide-in-anim {
	0% {
		position: relative;
		left: 100vw;
		height: 0px;
		overflow: hidden;

	}

	100% {
		position: relative;
		left: 0;
		height: auto;
	}

}

.cow-spawn {
	animation: cow-spawn-anim 0.6s linear forwards 3ms;
}

.cow-spawn .speech-bubble {
	animation: fade-in-anim 1s linear forwards 15ms;
}

.cow-talking {
	animation: cow-talking-anim 1.6s alternate infinite 3ms;
}


.fade-in {
	animation: fade-in-anim 0.7s linear forwards;
}

@keyframes fade-in-anim {
	0% {
		visibility: 0;
	}

	100% {
		visibility: 1;
	}

}


@keyframes cow-talking-anim {
	0% {
		transform: scale(0.9, 0.95) rotate(-10deg);
	}

	100% {

		transform: scale(1.0, 1.0) rotate(10deg);
	}
}

@keyframes cow-spawn-anim {
	0% {
		transform: scale(0.1, 0.1);
	}

	100% {
		transform: scale(1.0, 1.0);
	}
}

.hover-zoom:hover {
	animation: pulse-anim 1.1s linear forwards;
}

@keyframes pulse-anim {
	0% {
		transform: scale(1.0, 1.0);
	}

	100% {
		transform: scale(1.02, 1.02);
	}

}

/* Our Social Button Classes*/

.github {
	background-color: #333333 !important;
}

.wordpress {
	background-color: #23282d !important;
}

.figma {
	background-color: rgba(47, 128, 237, 1) !important;
}

.google {
	background-color: #dd4b39 !important;
}





/* The web-thumbnail  */

.web-thumbnail {
	position: relative;
	-ms-zoom: 0.25;
	-moz-transform: scale(0.25);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0.25);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(0.25);
	-webkit-transform-origin: 0 0;
}

.web-thumbnail iframe {
 border: 15px double var(--mdb-secondary) ;
  width: 1440px;
  height: 900px;
}

.web-thumbnail:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.web-thumbnail-container {
  width: calc(1440px * 0.25);
  height: calc(900px * 0.25);
  display: inline-block;
  overflow: hidden;
  position: relative;
  
}
.slim-gallery{
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0.3rem;    
    overflow: hidden;
    padding: 0.3rem;
}

.slim-gallery .item-list {    
    border-top: 1px solid rgba(0,0,0,0.1);
    overflow: auto;
    justify-content: center;
    display:flex;
    flex-direction: row;
    gap: 0.8rem;    
    padding:0.6rem;
}
.gallery-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    flex-direction: column;            
}

.gallery-preview:hover{
    cursor: zoom-in;
}

.gallery-preview > img{      
    box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.5);
}

.plan-addons-matrix{
    display: flex;
    border-radius: 0.3rem;
    gap: 0.3rem;
    flex-wrap: wrap;
    justify-content: stretch;
    }

.plan-addons-listing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:0.5rem;
    padding: 1rem;

}

.plan-addons-listing > div{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    gap:0.5rem;
    padding: 0.1rem;


}
.plan-addons-listing .selected{
    box-shadow: 0px 0px 0.3rem var(--mdb-success) !important;
    color: var(--mdb-success) ;
    font-size: 1.1rem;
}

.plan-addons-listing >  p{
    padding: 0;  
    margin:0;
    font-size: 0.9rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    
}

.plan-addons-listing > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
    padding: 0.3rem;
    box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1);

}
.plan-addons-listing > div > div > p{
    font-size: 0.7rem;
    padding:0;
    margin:0;
}
.plan-addon-ultimate{
    min-height:100%;
    min-width:8rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

}
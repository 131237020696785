.gallery-list-item {        
    border-radius: 0.3rem;
    box-shadow: 0.3rem 0.3rem 0.3rem rgba(0,0,0,0.6);
    width:5rem;
    height:5rem;
    overflow: hidden;
    position: relative;
}

.gallery-list-item > img {
    width: 100%;
    height: auto;
}

.gallery-list-item > p {    
    font-size: 0.7rem;
    text-align: center;
}
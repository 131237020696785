.gallery-lightbox{
    position: fixed;
    display: flex;
    justify-content: space-around;
    padding: 3rem;
    z-index: 3000;
    top: 0px;
    left:0px;
    bottom: 0px;
    right: 0px;
    overflow: auto;
    background-color: rgb(0,0,0,0.7);
    cursor: zoom-out;
}

